@import './colors.css';

.margin_top_row {
    margin-top: 1rem;
}

.half_margin_top_row {
    margin-top: 0.5rem;
}

.add_activity_button {
    color: var(--primary--color);
    cursor: pointer;
    font-size: 1.5rem;
    margin-top: 2rem;
    margin-right: 1rem;
    float: "right";
}

.edit_header_icon_save {
    cursor: pointer;
    margin-right: 15px;
    font-size: 1.5rem;
    color: var(--primary--color)
}

.custom_error_messages {
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

.edit_header_icon_close {
    cursor: pointer;
    font-size: 1.5rem;
    color: grey
}

.custom_checkbox input[type=checkbox] {
    transform: scale(1.5);
}

.edit_header_icon_add {
    cursor: pointer;
    font-size: 1.5rem;
    color: var(--primary--color)
}

.error_div {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

.parent_info_row {
    margin-bottom: 1rem;
}


/*  */

.mainPluginProfile{
    overflow-x: hidden;
}

.mainBody{
    height: 100vh;
    overflow-Y: auto;
}

.profileList{
    border-radius: 0px;
}

.noPadding {
    padding-right: 0px !important;
    padding-left: 0px !important;
}



.edit_icon{
    cursor: pointer;
    margin-right: 15px;
    font-size: 1rem;
    color: var(--primary--color);
}

.delete_icon {
    cursor: pointer;
    margin-right: 15px;
    font-size: 1rem;
    color: var(--declined--color) !important;
}

.enable_config {
    cursor: pointer;
    font-size: 1.5rem;
    color: var(--primary--color)
}

.disableConfig{
    cursor: pointer;
    font-size: 1.5rem;
    color: gray;;
}

.profileItem:hover {
    background-color: #007bff17;
}

.profileItem.selectedProfile {
    border: 1px solid #007bff56;
}

.profileListHeader {
    min-height: 1px;
    padding: 1.25rem;
}

.mainBodyList{
    height: 100vh;
    overflow-Y: auto;
    overflow-x: hidden;
    /* padding-right: 0px ; */
}

